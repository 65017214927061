.container-1 {
    border-bottom: #e4e4e4 1px solid;
  }
  
  .secondary-color {
    color: var(--main-header-hover-color);
  }
  
  .button-padding {
    padding: 13.875px !important;
    text-transform: none !important;
  }
  
  .search-bar {
    height: 54px;
  }
  
  .fields-styles {
    background-color: #e7e7e7 !important;
    border-radius: 5px;
    color: #888888 !important;
    border: 0.5px #b2b2b2 solid !important;
    outline: none !important;
  }
  
  .date-styles {
    background-color: #e7e7e7 !important;
    border-radius: 4px;
    color: #888888 !important;
    outline: none !important;
  }
  
  .modal-large {
    max-height: 90vh;
  }
  
  .table-style {
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
  }
  
  .table-row-style {
    border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  }
  
  .date-hour {
    background: var(--main-white-color);
    border-radius: 4px;
  }
  
  .cell {
    &-date {
      width: calc(34% - 8px);
    }
    &-name {
      width: calc(33% - 8px);
    }
    &-host {
      width: calc(33% - 8px);
    }
    &-name-body {
      background: var(--main-white-color);
    }
  }
  
  .visitor-column {
    width: 40%;
  }
  
  .host-column {
    width: 40%;
  }
  
  .trash-column {
    width: 20%;
  }
  
  .table-title-style {
    background: #eeeeee;
    color: var(--main-primary-color);
    border-bottom: rgba(0, 0, 0, 0.3) 1px solid;
  }
  
  .title-cell {
    border-left: rgba(0, 0, 0, 0.1) 1px solid;
    border-right: rgba(0, 0, 0, 0.1) 1px solid;
  }
  
  .svg-opacity {
    opacity: 0.75;
  }

  .details-style {
    background-color: #888888;
  }

  .divider-style {
    border-color: var(--main-primary-color) !important;
    border-width: "5px" !important;
  }

  .divider-div-style {
    height: 5px;
    background: var(--main-primary-color);
  }