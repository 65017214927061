.heading {
  color: var(--main-black-color);
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

h1 {
  @extend .heading;
  font-family: "Inter";
  font-weight: 400;
  font-size: 32px;
  letter-spacing: -0.005em;
  line-height: 32px;
}

h2 {
  @extend .heading;
  font-family: "Inter";
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

h3 {
  @extend .heading;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.005em;
}

h4 {
  @extend .heading;
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter";
  font-weight: 400;
  letter-spacing: -0.005em;
}

h5 {
  @extend .heading;
  color: var(--gray-dark);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter";
  letter-spacing: -0.005em;
}

h6 {
  @extend .heading;
  color: var(--gray-dark);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter";
  letter-spacing: -0.005em;
}
