.main-list-container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.main-list-container-deleted {
  // background-color: #b8b8b8;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(161, 161, 161, 0.15);
  position: relative;
}

.deleted-text {
  // color: rgba(255, 255, 255, 0.7);
  color: #a60300;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  opacity: 75%;
}

.deleted-text-container {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50%);
}
