.filters-main-container {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 176px;
  left: 0;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
  animation: in 0.2s ease-out;
  z-index: 96;
}

.filters-main-container-out {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 126px;
  left: 0;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
  animation: out 0.2s ease-out;
  animation-play-state: running;
  z-index: 96;
}

@keyframes in {
  from {top: 126px;}
  to {top: 176px;}
}

@keyframes out {
  from {top: 176px;}
  to {top: 126px;}
}
