.credential {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: 1em;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.show-content {
  width: 100%;
  max-height: 0px;
  transition: max-height 0.3s ease-in-out;
}

.show-content.expanded {
  max-height: 500px;
}
