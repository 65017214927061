.admin-desktop-invitations-main-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.admin-desktop-invitations-filters-container {
  width: 100%;
  box-sizing: border-box;
  min-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-desktop-invitations-td {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.admin-desktop-invitations-th {
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 500;
}

.admin-desktop-invitations-thead {
  height: auto;
  background-color: #e8e8e8;
}

.admin-desktop-invitations-status-icons-container {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.admin-desktop-invitations-table-container {
  background-color: white;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 4px;
  position: relative;
  box-shadow: rgba(60, 64, 67, 0.05) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: auto;
}

.admin-desktop-invitations-table-body-container {
  width: 100%;
  overflow-y: auto;
  background-color: #f8f8f8;
}

.admin-desktop-invitations-loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-desktop-invitations-share-content-container {
  position: absolute;
  right: -20px;
  top: 35px;
  z-index: 100;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 10px;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 1px;
  display: flex;
  box-shadow: rgba(60, 64, 67, 0.05) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
