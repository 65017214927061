.font-0 {
  font-size: 0px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-32 {
  font-size: 32px;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-700 {
  font-weight: 700;
}
