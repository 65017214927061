.button-container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 60px;
  z-index: 99;
  position: fixed;
  bottom: 70px;
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px #d9d9d9 solid;
}
