.photo-size {
max-width: '100%';
}

.company-logo {
  height: 45px;
}

.passtrack-logo {
  height: 28px;
}
