.mobile-container {
  margin-top: 60px;
}

.side-menu-container {
  width: 250px;
}

.content-container {
  margin-left: 250px;
  width: calc(100% - 250px);
}

.account-menu {
  width: 100px;
}

.account-options {
  bottom: -60px;
  right: 0;
}

