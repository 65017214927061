.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.flex-dir-col {
  flex-direction: column !important;
}

.flex-dir-row {
  flex-direction: row;
}

.space-btwn {
  justify-content: space-between;
}

.self-end {
  justify-self: flex-end;
  margin-left: auto;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.horizontal-spacing-0 {
  > *:not(:last-child) {
    margin-right: 0 !important;
  }
}
.horizontal-spacing-1 {
  > *:not(:last-child) {
    margin-right: 2px !important;
  }
}
.horizontal-spacing-2 {
  > *:not(:last-child) {
    margin-right: 4px !important;
  }
}
.horizontal-spacing-3 {
  > *:not(:last-child) {
    margin-right: 6px !important;
  }
}
.horizontal-spacing-4 {
  > *:not(:last-child) {
    margin-right: 8px !important;
  }
}
.horizontal-spacing-5 {
  > *:not(:last-child) {
    margin-right: 12px !important;
  }
}
.horizontal-spacing-6 {
  > *:not(:last-child) {
    margin-right: 14px !important;
  }
}
.horizontal-spacing-7 {
  > *:not(:last-child) {
    margin-right: 16px !important;
  }
}
.horizontal-spacing-8 {
  > *:not(:last-child) {
    margin-right: 20px !important;
  }
}
.horizontal-spacing-9 {
  > *:not(:last-child) {
    margin-right: 24px !important;
  }
}
.horizontal-spacing-10 {
  > *:not(:last-child) {
    margin-right: 32px !important;
  }
}
.horizontal-spacing-11 {
  > *:not(:last-child) {
    margin-right: 40px !important;
  }
}

.vertical-spacing-0 {
  > *:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
.vertical-spacing-1 {
  > *:not(:last-child) {
    margin-bottom: 2px !important;
  }
}
.vertical-spacing-2 {
  > *:not(:last-child) {
    margin-bottom: 4px !important;
  }
}
.vertical-spacing-3 {
  > *:not(:last-child) {
    margin-bottom: 6px !important;
  }
}
.vertical-spacing-4 {
  > *:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
.vertical-spacing-5 {
  > *:not(:last-child) {
    margin-bottom: 12px !important;
  }
}
.vertical-spacing-6 {
  > *:not(:last-child) {
    margin-bottom: 14px !important;
  }
}
.vertical-spacing-7 {
  > *:not(:last-child) {
    margin-bottom: 16px !important;
  }
}
.vertical-spacing-8 {
  > *:not(:last-child) {
    margin-bottom: 20px !important;
  }
}
.vertical-spacing-9 {
  > *:not(:last-child) {
    margin-bottom: 24px !important;
  }
}
.vertical-spacing-10 {
  > *:not(:last-child) {
    margin-bottom: 32px !important;
  }
}
.vertical-spacing-11 {
  > *:not(:last-child) {
    margin-bottom: 40px !important;
  }
}
