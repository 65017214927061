.id {
    width: 95%;
}

.plate {
    width: 98%;
}

.person {
    width: 90%;
}

.computer {
    width: 98%;
}

.back-id {
    width: 100%;
}

