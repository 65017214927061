.example-window-div-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
}
.example-window-div-2 {
  --b: 3px; /* thickness of the border */
  --c: #666; /* color of the border */
  --w: 30px; /* width of border */

  border: var(--b) solid transparent; /* space for the border */
  --g: #0000 90deg, var(--c) 0;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin: 0px;
  padding: 0px;
}
.example-window-div-3 {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: #f2f2f2;
}
.example-window-div-4 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0px;
  padding: 0px;
  padding-top: 49%;
  border: lightgrey 2px solid;
  border-radius: 5px;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;
}

.main-container-bg {
  background: #dfdfdf;
  border-radius: 4px;
}
