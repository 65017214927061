.main-container {
  width: 100vw;
  height: 116px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--main-primary-color);
  padding-top: 60px;
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  z-index: 97;
}

.main-container-desktop {
  width: 100%;
  height: 116px;
  background-color: #d9d9d9;
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  z-index: 97;
}

.title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-text {
  color: white;
  font-size: 24px;
  font-weight: normal;
}
