.mobile-logo {
  height: 50px;
}

.desktop-logo {
  height: 75px;
}

.network-logo {
  height: 45px;
}

.menu-bar {
  height: 60px;
}

.menu-bar-guard {
  height: 60px;
}

.indicator {
  width: 5px;
  &-selected {
    background: var(--main-primary-color);
  }
}

.bg-selected {
  background-color: var(--main-header-color);
}

.menu-item {
  &:hover {
    .indicator {
      background: var(--main-primary-lighter-color);
      &-selected {
        background: var(--main-primary-color);
      }
    }
  }
}

.paper-no-style {
  background-color: white !important;
  box-shadow: none !important;
}
