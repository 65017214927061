.div-webcam-container {
  width: 94.11764705882353%;
  height: calc(100% * 0.7);
  background: black;
  border-radius: 6px;
}

.webcam-style {
  border: lightgrey 8px solid;
  border-radius: 6px;
}

.image-style {
  width: 94.11764705882353%;
  border: lightgrey 8px solid;
  border-radius: 6px;
}

.icon-button-style {
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.back1 {
  background-color: aqua;
}
