.div-sub-tag {
    background: var(--main-gray-color);
}

.button-paper-style {
    height: auto;
}

.paper-text {
    font-size: 28px;
    margin: 0;
}

.paper-text-mobile {
    font-size: 18px;
    margin: 0;
}

