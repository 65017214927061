.add-guest-button-style {
  color: white !important;
  text-transform: none !important;
  font-weight: lighter !important;
}

.background-guest-div {
    background: #f2f2f2;
    border-radius: 5px;
  }

.create-event-button {
    font-weight: lighter !important;
}
