.container-1 {
  border-bottom: #e4e4e4 1px solid;
}

.secondary-color {
  color: var(--main-header-hover-color);
}

.button-padding {
  padding: 13.875px !important;
  text-transform: none !important;
}

.search-bar {
  height: 54px;
}

.fields-styles {
  background-color: #e7e7e7 !important;
  border-radius: 5px;
  color: #888888 !important;
  border: 0.5px #b2b2b2 solid !important;
  outline: none !important;
}

.date-styles {
  background-color: #e7e7e7 !important;
  border-radius: 4px;
  color: #888888 !important;
  outline: none !important;
}

.modal-large {
  max-height: 90vh;
}

.table-style {
  width: 100%;
  border-radius: 5px;
  border-collapse: collapse;
}

.table-row-style {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.date-hour {
  background: var(--main-white-color);
  border-radius: 4px;
}

.cell {
  &-date {
    width: calc(34% - 8px);
  }
  &-name {
    width: calc(33% - 8px);
  }
  &-host {
    width: calc(33% - 8px);
  }
  &-name-body {
    background: var(--main-white-color);
  }
}

.date-invitation-column {
  width: 24%;
}

.name-invitation-column {
  width: 29%;
}

.host-invitation-column {
  width: 29%;
}

.status-invitation-column {
  width: 18%;
}

.table-title-style {
  background: #eeeeee;
  color: var(--main-primary-color);
  border-bottom: rgba(0, 0, 0, 0.3) 1px solid;
}

.title-cell {
  border-left: rgba(0, 0, 0, 0.1) 1px solid;
  border-right: rgba(0, 0, 0, 0.1) 1px solid;
}

.title-cell-left {
  border-left: rgba(0, 0, 0, 0.1) 1px solid;
}

.svg-opacity {
  opacity: 0.75;
}

.w-auto {
  width: auto;
}

.div-relative {
  position: relative;
}

.date-box {
  background: none !important;
  border: 1px solid var(--main-secondary-color) !important;
  border-radius: 4px;
  padding: 0px !important;
  margin: 0px !important;
  width: 130px !important;
  height: 35px !important;
}

.date-box-null {
  background: none !important;
  border: 1px solid rgba(138, 140, 145, 0.5) !important;
  border-radius: 4px;
  padding: 0px !important;
  margin: 0px !important;
  width: 130px !important;
  height: 35px !important;
}

.no-date {
  position: absolute !important;
  top: 17%;
  left: 27%;
  pointer-events: none;
}

.div-filters {
  height: 50px;
}

.tab-style {
  position: relative !important;
  text-transform: none !important;
}

.icon-one {
  position: absolute !important;
  top: 55%;
  left: 42%;
  pointer-events: none;
  color: var(--main-secondary-color) !important;
}

.icon-one-b {
  position: absolute !important;
  top: 55%;
  left: 42%;
  pointer-events: none;
  color: var(--main-secondary-color) !important;
  opacity: 30% !important;
}

.icon-two {
  position: absolute !important;
  top: 55%;
  left: 92%;
  pointer-events: none;
  color: var(--main-secondary-color) !important;
}

.icon-two-b {
  position: absolute !important;
  top: 55%;
  left: 92%;
  pointer-events: none;
  color: var(--main-secondary-color) !important;
  opacity: 30% !important;
}

.filter-div-style {
  background: var(--main-secondary-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.text-filter-div {
  color: white;
  text-transform: uppercase;
}

.pagination-container {
  background-color: #e8e8e8;
  width: 100vw;
  height: 43px;
  z-index: 99;
  position: fixed;
  bottom: 70px;
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px #d9d9d9 solid;
}

.pagination-container-nofixed {
  background-color: #e8e8e8;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
}

.date-filter {
  background: white !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 40px !important;
}

.date-filter-null {
  background: white !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 40px !important;
}

.no-date-filter {
  position: absolute !important;
  top: 30%;
  left: calc(50% - 15px);
  pointer-events: none;
}

.padding-in {
  position: relative;
  top: 176px;
  animation: in 0.2s ease-out;
}

.padding-out {
  position: relative;
  top: 116px;
  animation: out 0.2s ease-out;
}
