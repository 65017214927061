@import "./margin.scss";
@import "./padding.scss";
@import "./flex.scss";
@import "./font.scss";
@import "./heading.scss";
@import "./z-position.scss";
@import "./colors.scss";

* {
  // font-family: "Roboto", sans-serif;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.desktop-tables-font {
  font-weight: 200;
}

.background-general {
  background: #f8f8f8 !important;
}

.root-background {
  background: var(--main-white-color);
}

:root {
  --main-title-color: #8a8c91;
  --main-subtitle-color: #666;
  --main-links-color: #10418a;
  --main-header-color: #f0f2f5;
  --main-header-hover-color: #979797;
  --main-header-text-color: #10418a;
  --main-header-border-color: #ff8610;
  --main-highlight-orange-color: #ff8000;
  --main-highlight-orange-lighter-color: #ff9000;
  --main-highlight-color: #ff8000;
  --main-highlight-lighter-color: #ff9000;
  --main-primary-color: #10418a;
  --main-primary-lighter-color: #0158b1;
  --main-primary-darker-color: #10418a;
  --main-secondary-color: #ff8000;
  --main-secondary-lighter-color: #ff8900;
  --main-gray-color: #dfdfdf;
  --main-gray-lighter-color: #eee;
  --main-gray-darker-color: #444444;
  --main-alerts-color: #eed3d7;
  --main-alerts-lighter-color: #ffeed8;
  --main-white-color: #f8f8f8;
  --main-white-darker-color: #3e3e3e;
  --main-black-color: #000000;
  --main-primary-button-color: #10418a;
  --main-primary-menu-color: #10418a;
  --main-white-darker-menu-color: #f9f9f9;
  --main-white-menu-color: #ffffff;
  --main-disabled-color: #bdbdbd;
}

html {
  min-height: 100%;
  position: relative;
  height: auto;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

body {
  background: var(--bg-color);
  font-size: 14px;
  height: auto;
  background-color: var(--main-white-color);
  margin: 0;
}

#root {
  min-height: 100%;
}

.normal {
  font-weight: normal !important;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: 600 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.italic {
  font-style: italic !important;
}

.text-justify {
  text-align: justify !important;
}

.default-cursor {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.b-0 {
  bottom: 0;
}

.t-0 {
  top: 0;
}

.w-100 {
  width: 100%;
}

.w-95 {
  width: 95%;
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-65 {
  width: 65%;
}

.w-60 {
  width: 60%;
}

.w-55 {
  width: 55%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.vw-100 {
  width: 100vw;
}

.h-100 {
  height: 100%;
}

.h-95 {
  height: 95%;
}

.h-90 {
  height: 90%;
}

.h-85 {
  height: 85%;
}

.h-80 {
  height: 80%;
}

.h-75 {
  height: 75%;
}

.h-70 {
  height: 70%;
}

.h-65 {
  height: 65%;
}

.h-60 {
  height: 60%;
}

.h-55 {
  height: 55%;
}

.h-50 {
  height: 50%;
}

.h-45 {
  height: 45%;
}

.h-40 {
  height: 40%;
}

.h-35 {
  height: 35%;
}

.h-30 {
  height: 30%;
}

.h-25 {
  height: 25%;
}

.h-20 {
  height: 20%;
}

.h-15 {
  height: 15%;
}

.h-180 {
  height: 180px;
}

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.vh-85 {
  height: 85vh;
}

.vh-80 {
  height: 80vh;
}

.border-box {
  box-sizing: border-box;
}

.selected-icon-button {
  background-color: var(--main-gray-lighter-color) !important;
  border-radius: 5px;
}

.selected-filter-button {
  background-color: var(--main-secondary-color) !important;
  color: white !important;
}

.selected-filter-icon-button {
  color: var(--main-secondary-color);
}

.selected-filter-text-field {
  border: var(--main-secondary-color) 1px solid !important;
}

.upper-shadow {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
}

.lower-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.upper-shadow-two {
  box-shadow: 0px -3px 3px -2px rgba(0, 0, 0, 0.05),
    0px -3px 4px 0px rgba(0, 0, 0, 0.05), 0px -1px 8px 0px rgba(0, 0, 0, 0.05) !important;
}

.m-p-0i {
  margin: 0px !important;
  padding: 0px !important;
}

.lower-div {
  width: 100%;
  height: 74px;
}

.modal {
  display: block !important;
}
.modal-dialog {
  overflow-y: initial !important;
  overflow: "scroll";
}
.modal-body {
  height: auto;
  overflow-y: auto;
}

.paper-min-height {
  min-height: 70vh;
}

.qr-tel-style {
  background: white;
  border-radius: 4px;
}

.qr-tel-style-2 {
  background: var(--main-white-color);
  border-radius: 4px;
}

.button-style {
  border-radius: 4px;
  width: 100px;
}

.button-text-style {
  text-transform: inherit !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

.anim-top-bottom {
  transition: 0.5s;
  transform: translateY(100%);
}

.credential-image {
  max-width: 100%;
  max-height: 100%;
}

