.dg-header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--main-primary-color);
}

.dg-header-two {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.dg-main-containers {
  width: calc(100% / 3);
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.dg-blue-color {
  color: var(--main-primary-color);
}

.header-selector {
  background-color: white;
}

.header-logo-size-1 {
  height: 30px;
  padding: 5px;
}

.header-logo-size-2 {
  height: 25px;
}

.admin-logo-passtrack {
  height: 70px;
}
