// padding
.p {
  &-0 {
    padding: 0 !important;
  }
  &-1 {
    padding: 2px !important;
  }
  &-2 {
    padding: 4px !important;
  }
  &-3 {
    padding: 6px !important;
  }
  &-4 {
    padding: 8px !important;
  }
  &-5 {
    padding: 12px !important;
  }
  &-6 {
    padding: 14px !important;
  }
  &-7 {
    padding: 16px !important;
  }
  &-8 {
    padding: 20px !important;
  }
  &-9 {
    padding: 24px !important;
  }
  &-10 {
    padding: 32px !important;
  }
  &-11 {
    padding: 40px !important;
  }
  &-12 {
    padding: 48px !important;
  }
  &-13 {
    padding: 56px !important;
  }
}

// padding-top & padding-bottom
.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  &-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  &-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  &-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  &-5 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  &-6 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  &-7 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  &-8 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  &-9 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  &-10 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  &-11 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  &-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  &-13 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
}

// padding-left & padding-right
.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  &-2 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  &-3 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  &-4 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  &-5 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  &-6 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  &-7 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  &-8 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  &-9 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  &-10 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  &-11 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  &-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  &-13 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
}

// padding-top
.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-1 {
    padding-top: 2px !important;
  }
  &-2 {
    padding-top: 4px !important;
  }
  &-3 {
    padding-top: 6px !important;
  }
  &-4 {
    padding-top: 8px !important;
  }
  &-5 {
    padding-top: 12px !important;
  }
  &-6 {
    padding-top: 14px !important;
  }
  &-7 {
    padding-top: 16px !important;
  }
  &-8 {
    padding-top: 20px !important;
  }
  &-9 {
    padding-top: 24px !important;
  }
  &-10 {
    padding-top: 32px !important;
  }
  &-11 {
    padding-top: 40px !important;
  }
  &-12 {
    padding-top: 48px !important;
  }
  &-13 {
    padding-top: 56px !important;
  }
}

// padding-right
.pr {
  &-0 {
    padding-right: 0 !important;
  }
  &-1 {
    padding-right: 2px !important;
  }
  &-2 {
    padding-right: 4px !important;
  }
  &-3 {
    padding-right: 6px !important;
  }
  &-4 {
    padding-right: 8px !important;
  }
  &-5 {
    padding-right: 12px !important;
  }
  &-6 {
    padding-right: 14px !important;
  }
  &-7 {
    padding-right: 16px !important;
  }
  &-8 {
    padding-right: 20px !important;
  }
  &-9 {
    padding-right: 24px !important;
  }
  &-10 {
    padding-right: 32px !important;
  }
  &-11 {
    padding-right: 40px !important;
  }
  &-12 {
    padding-right: 48px !important;
  }
  &-13 {
    padding-right: 56px !important;
  }
}

// padding-bottom
.pb {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: 2px !important;
  }
  &-2 {
    padding-bottom: 4px !important;
  }
  &-3 {
    padding-bottom: 6px !important;
  }
  &-4 {
    padding-bottom: 8px !important;
  }
  &-5 {
    padding-bottom: 12px !important;
  }
  &-6 {
    padding-bottom: 14px !important;
  }
  &-7 {
    padding-bottom: 16px !important;
  }
  &-8 {
    padding-bottom: 20px !important;
  }
  &-9 {
    padding-bottom: 24px !important;
  }
  &-10 {
    padding-bottom: 32px !important;
  }
  &-11 {
    padding-bottom: 40px !important;
  }
  &-12 {
    padding-bottom: 48px !important;
  }
  &-13 {
    padding-bottom: 56px !important;
  }
}

// padding-left
.pl {
  &-0 {
    padding-left: 0 !important;
  }
  &-1 {
    padding-left: 2px !important;
  }
  &-2 {
    padding-left: 4px !important;
  }
  &-3 {
    padding-left: 6px !important;
  }
  &-4 {
    padding-left: 8px !important;
  }
  &-5 {
    padding-left: 12px !important;
  }
  &-6 {
    padding-left: 14px !important;
  }
  &-7 {
    padding-left: 16px !important;
  }
  &-8 {
    padding-left: 20px !important;
  }
  &-9 {
    padding-left: 24px !important;
  }
  &-10 {
    padding-left: 32px !important;
  }
  &-11 {
    padding-left: 40px !important;
  }
  &-12 {
    padding-left: 48px !important;
  }
  &-13 {
    padding-left: 56px !important;
  }
}
