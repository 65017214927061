// margin
.m {
  &-0 {
    margin: 0 !important;
  }
  &-1 {
    margin: 2px !important;
  }
  &-2 {
    margin: 4px !important;
  }
  &-3 {
    margin: 6px !important;
  }
  &-4 {
    margin: 8px !important;
  }
  &-5 {
    margin: 12px !important;
  }
  &-6 {
    margin: 14px !important;
  }
  &-7 {
    margin: 16px !important;
  }
  &-8 {
    margin: 20px !important;
  }
  &-9 {
    margin: 24px !important;
  }
  &-10 {
    margin: 32px !important;
  }
  &-11 {
    margin: 40px !important;
  }
  &-n1 {
    margin: calc(2px * -1) !important;
  }
  &-n2 {
    margin: calc(4px * -1) !important;
  }
  &-n3 {
    margin: calc(6px * -1) !important;
  }
  &-n4 {
    margin: calc(8px * -1) !important;
  }
}

// margin-top & margin-bottom
.my {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  &-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  &-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  &-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  &-5 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  &-6 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  &-7 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  &-8 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  &-9 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  &-10 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  &-11 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  &-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  &-13 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
}

// margin-left & margin-right
.mx {
  &-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-1 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  &-2 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  &-3 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  &-4 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  &-5 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  &-6 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  &-7 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  &-8 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  &-9 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  &-10 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  &-11 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  &-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  &-13 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
}

// margin-top
.mt {
  &-0 {
    margin-top: 0 !important;
  }
  &-1 {
    margin-top: 2px !important;
  }
  &-2 {
    margin-top: 4px !important;
  }
  &-3 {
    margin-top: 6px !important;
  }
  &-4 {
    margin-top: 8px !important;
  }
  &-5 {
    margin-top: 12px !important;
  }
  &-6 {
    margin-top: 14px !important;
  }
  &-7 {
    margin-top: 16px !important;
  }
  &-8 {
    margin-top: 20px !important;
  }
  &-9 {
    margin-top: 24px !important;
  }
  &-10 {
    margin-top: 32px !important;
  }
  &-11 {
    margin-top: 40px !important;
  }
  &-12 {
    margin-top: 48px !important;
  }
  &-13 {
    margin-top: 56px !important;
  }
  &-n1 {
    margin-top: calc(2px * -1) !important;
  }
  &-n2 {
    margin-top: calc(4px * -1) !important;
  }
  &-n3 {
    margin-top: calc(6px * -1) !important;
  }
}

// margin-right
.mr {
  &-auto {
    margin-left: auto !important;
  }
  &-0 {
    margin-right: 0 !important;
  }
  &-1 {
    margin-right: 2px !important;
  }
  &-2 {
    margin-right: 4px !important;
  }
  &-3 {
    margin-right: 6px !important;
  }
  &-4 {
    margin-right: 8px !important;
  }
  &-5 {
    margin-right: 12px !important;
  }
  &-6 {
    margin-right: 14px !important;
  }
  &-7 {
    margin-right: 16px !important;
  }
  &-8 {
    margin-right: 20px !important;
  }
  &-9 {
    margin-right: 24px !important;
  }
  &-10 {
    margin-right: 32px !important;
  }
  &-11 {
    margin-right: 40px !important;
  }
  &-12 {
    margin-right: 48px !important;
  }
  &-13 {
    margin-right: 56px !important;
  }
}

// margin-bottom
.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-bottom: 2px !important;
  }
  &-2 {
    margin-bottom: 4px !important;
  }
  &-3 {
    margin-bottom: 6px !important;
  }
  &-4 {
    margin-bottom: 8px !important;
  }
  &-5 {
    margin-bottom: 12px !important;
  }
  &-6 {
    margin-bottom: 14px !important;
  }
  &-7 {
    margin-bottom: 16px !important;
  }
  &-8 {
    margin-bottom: 20px !important;
  }
  &-9 {
    margin-bottom: 24px !important;
  }
  &-10 {
    margin-bottom: 32px !important;
  }
  &-11 {
    margin-bottom: 40px !important;
  }
  &-12 {
    margin-bottom: 48px !important;
  }
  &-13 {
    margin-bottom: 56px !important;
  }

  &-n1 {
    margin-bottom: calc(2px * -1) !important;
  }
  &-n2 {
    margin-bottom: calc(4px * -1) !important;
  }
  &-n3 {
    margin-bottom: calc(6px * -1) !important;
  }
}

// margin-left
.ml {
  &-auto {
    margin-left: auto !important;
  }
  &-0 {
    margin-left: 0 !important;
  }
  &-1 {
    margin-left: 2px !important;
  }
  &-2 {
    margin-left: 4px !important;
  }
  &-3 {
    margin-left: 6px !important;
  }
  &-4 {
    margin-left: 8px !important;
  }
  &-5 {
    margin-left: 12px !important;
  }
  &-6 {
    margin-left: 14px !important;
  }
  &-7 {
    margin-left: 16px !important;
  }
  &-8 {
    margin-left: 20px !important;
  }
  &-9 {
    margin-left: 24px !important;
  }
  &-10 {
    margin-left: 32px !important;
  }
  &-11 {
    margin-left: 40px !important;
  }
  &-12 {
    margin-left: 48px !important;
  }
  &-13 {
    margin-left: 56px !important;
  }
}
