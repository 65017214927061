.qr-code-background {
    border-radius: 4px;
    margin-top: 18px;
    width: auto;
}

.qr-container {
    display: flex;
    background-color: white;
    padding: 14px;
    justify-content: center;
}

.icons-container {
    width: 60%;
    margin-top: 10px;
}

.state-container {
    background-color: white;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
}