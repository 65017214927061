.title-color {
  color: var(--main-title-color) !important;
}

.subtitle-color {
  color: var(--main-subtitle-color) !important;
}

.subtitle-color-ni {
  color: var(--main-subtitle-color);
}

.light-grey {
  color: #bdbdbd !important;
}

.text-white {
  color: white !important;
}

.text-disabled {
  color: #a2a3a1 !important;
}

.primary-color {
  color: var(--main-primary-color) !important;
}

.text-primary-color {
  color: var(--main-primary-color) !important;
}

.text-secondary-color {
  color: var(--main-secondary-color) !important;
}

.text-disabled-color {
  color: var(--main-disabled-color) !important;
}

.main-links-color {
  color: var(--main-links-color);
}

.text-warning {
  color: #a60300;
}

.allowed {
  color: #12941a;
}

.allowed-related {
  color: #71bf76;
}

.not-allowed {
  color: #a60300;
}

.blank {
  color: white;
}

.background-primary {
  background-color: #f2f2f2;
  // background-color: #ebebeb;
}

.border-primary {
  border-radius: 6px;
}

.general-background {
  background: #f7fbf8;
}

.background-gray-color {
  background-color: #e8e8e8;
}

.background-border-grey {
  background: #f2f2f2;
  border: lightgrey 2px solid;
  border-radius: 5px;
}

.background-disabled-color {
  background-color: #dadbda !important;
}

.background-primary-color {
  background-color: var(--main-primary-color) !important;
}

.call-icon-button-style-icon {
  color: white !important;
}

.call-icon-button-style-button {
  background: var(--main-primary-color) !important;
}

.image-border {
  // width: 94.11764705882353%;
  // width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  max-height: 40vh;
  // border: lightgrey 8px solid;
  // border-radius: 6px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.background-white {
  background: white !important;
}

.background-primary-color {
  background: var(--main-primary-color) !important;
}

.shadow-one {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.border-bottom-primary {
  border-bottom: var(--main-primary-color) 4px solid;
}

.guard-regiter-inv-button {
  background-color: var(--main-primary-color);
}

.guard-regiter-inv-button:hover {
  background-color: #002884;
}
