.lower-menu-style {
    height: auto;
}

.lower-menu-button-style {
    border: none !important;
    // width: 25vw;
    border-radius: 0px !important;
}

.upper-line-button {
    position: absolute;
    top: 0;
    height: 4px;
    background: var(--main-primary-color);
}

.general-upper-line {
    position: absolute;
    top: 0;
    height: 2px;
    background-color: var(--main-primary-color) !important;
}

.pressed-menu-button {
    // background: #dfdfdf !important;
    // background: var(--main-white-color) !important;
    // background: var(--main-primary-color) !important;
    // color: white !important;
    // background: #eee !important;
}