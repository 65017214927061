.days-rows-1-style {
  background: #eeeeee;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.days-rows-2-style {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  background: var(--main-white-color);
}

.days-table-general-style {
  width: auto;
  border-radius: 5px;
  border-collapse: collapse;
}

.check-style {
  margin-left: 20px;
  margin-right: 20px;
}

.days-container {
  background: #eeeeee;
  // border: 1px var(--main-disabled-color) solid;
  border-radius: 25px;
  height: 50px;
}

.single-day-container-on {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background-color: var(--main-primary-color);
  border: var(--main-primary-color) solid 1px;
}

.single-day-container-off {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: var(--main-primary-color) solid 1px;
}
